import React, { FunctionComponent, createContext, useEffect } from 'react';
import { useStore } from 'effector-react';

import { EventBus, subscribeEvent } from '../utils/bus';

import { CurrentContractStore } from '../effector/dashboard';
import { CarrotQuestState, ChatStore, setChatIsInitialized, setChatState } from '../effector/chat';

import { makeContractHash } from '../types/contract';

declare module '../utils/bus' {
    interface BusEvents {
        onCarrotQuestInit: void;
    }
}

type CarrotQuestTrackMessageKind = 'read' | 'replied' | 'clicked';
type CarrotQuestUserData = {
    $name: string;
    $email: string;
    $phone: number;
    $user_id: number;
};

type CarrotQuestUserDataPayload = Partial<CarrotQuestUserData>;

/**
 * CarrotQuest widget provides you with small JavaScript API on your website.
 * With it you can control and customize your chat and widget behavior.
 *
 * See documentation:
 *
 * [JS API](https://developers.carrotquest.io/libs/js)
 */
interface CarrotQuest {
    /** Launched methods (like a GTM) */
    settings: {};

    // --- API Methods ---
    connect(apiKey: string): void;

    track(eventName: string, callback?: Noop): void;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    identify(data: any, options?: any): void;

    auth(userId: number, hash: string): void;

    open(): void;

    onReady(callback: Noop): void;

    addCallback(topic: string, callback: Noop): void;

    removeCallback(topic: string, callback: Noop): void;

    trackMessageInteraction(sendingId: string, type: CarrotQuestTrackMessageKind): void;

    removeChat(): void;
}

type CarrotQuestMethods = keyof CarrotQuest;

declare global {
    interface Window {
        carrotquest: CarrotQuest;
        carrotquestasync: any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
    }
}

const methods: CarrotQuestMethods[] = [
    'connect',
    'track',
    'identify',
    'auth',
    'onReady',
    'addCallback',
    'removeCallback',
    'trackMessageInteraction',
];

const load = (): void => {
    const CarrotQuestAppSecret = window.carrotQuestSecret;

    if (!window.carrotquest && CarrotQuestAppSecret) {
        window.carrotquest = {
            settings: {},
        } as CarrotQuest;
        window.carrotquestasync = [];

        const s = document.createElement('script');
        s.async = true;
        s.type = 'text/javascript';
        s.src = '//cdn.carrotquest.app/api.min.js';
        s.onload = () => EventBus.emit('onCarrotQuestInit');

        document.body.appendChild(s);

        const initMethod = (a: CarrotQuestMethods) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return (...args: any) => {
                window.carrotquestasync.push(a, args);
            };
        };

        methods.forEach((method) => {
            window.carrotquest[method] = initMethod(method);
        });

        window.carrotquest.connect('49154-e8f79ca672a6c2ac8a825a4c25');
    }
};

export type CarrotQuestProviderContext = {
    chatInstance: CarrotQuest;
    chatState: CarrotQuestState;
};

const InitializeTimeout = 15000;

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const CarrotQuestContext = createContext<CarrotQuestProviderContext>(null!);
export const CarrotQuestProvider: FunctionComponent<{ needInitCarrot: boolean }> = ({ needInitCarrot, children }) => {
    const currentContract = useStore(CurrentContractStore);
    const { state, isInitialized } = useStore(ChatStore);
    const CarrotQuestAppSecret = window.carrotQuestSecret;

    const identify = (): void => {
        const chatInstance = window.carrotquest;

        if (currentContract === null || chatInstance === undefined) return;

        const payload: CarrotQuestUserDataPayload = {
            $name: currentContract.title ? currentContract.title : makeContractHash(currentContract),
            $user_id: currentContract.id,
        };

        chatInstance.identify(payload);
    };

    subscribeEvent('onCarrotQuestInit', () => {
        const chatInstance = window.carrotquest;
        if (!CarrotQuestAppSecret || !chatInstance) return;

        setChatState('loading');
        if (!isInitialized) {
            const fallbackTimeout = setTimeout(() => {
                setChatState('failed');
                setChatIsInitialized(false);
            }, InitializeTimeout);

            chatInstance.onReady(() => {
                setChatState('success');
                setChatIsInitialized(true);
                clearTimeout(fallbackTimeout);
                identify();
            });
        } else {
            setChatState('success');
        }
    });

    useEffect(() => {
        if (needInitCarrot && CarrotQuestAppSecret) {
            load();
            identify();
        }
    }, [currentContract, needInitCarrot]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <CarrotQuestContext.Provider value={{ chatInstance: window.carrotquest, chatState: state }}>
            {children}
        </CarrotQuestContext.Provider>
    );
};
